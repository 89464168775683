<template>
  <div class="main-box">
    <el-card class="box-card">
      <el-table :data="configList" border stripe>
        <el-table-column label="配置名称" prop="name"></el-table-column>
        <el-table-column label="配置描述" prop="desc"></el-table-column>
        <el-table-column label="配置值" prop="value"></el-table-column>
        <el-table-column label="操作">
          <el-button type="primary" size="mini"><i class="el-icon-edit"></i> 修改</el-button>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Config",
  data(){
    return{
      configList:[{
        desc:'token有效时间(分钟)',
        name:'sys_token_expire_minute',
        value:'60'
      },{
        desc:'临时路径',
        name:'temp_dir',
        value:'d://temp'
      }]
    }
  }
}
</script>

<style scoped>

</style>
